import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from 'src/services/auth-guard.service';
import { LogoutComponent } from './logout/logout.component';
import { OverviewPage } from './overview/overview.page';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'form',
    loadChildren: () =>
      import('./registration-form/registration-form.module').then(
        (m) => m.RegistrationFormModule
      ),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'referer',
    loadChildren: () =>
      import('./admin/referers/referer-form/referer-form.module').then(
        (m) => m.RefererFormModule
      ),
  },
  {
    path: 'referer',
    loadChildren: () =>
      import('./admin/referers/referer-form/referer-form.module').then(
        (m) => m.RefererFormModule
      ),
  },
  {
    path: 'overview',
    loadChildren: () =>
      import('./overview/overview.module').then(
        (m) => m.OverviewModule
      ),
  },
  {
    path: 'otl',
    loadChildren: () =>
      import('./otl/otl.module').then(
        (m) => m.OTLModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
