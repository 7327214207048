import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { JWT_Payload } from 'froitzheim-shared';
import { MegaMenuItem, PrimeNGConfig } from 'primeng/api';
import { filter } from 'rxjs';
import { User } from 'src/dto/user.dto';
import { ApplicationService } from 'src/services/application.service';
import { AuthService } from 'src/services/auth.service';
import { PermService } from 'src/services/perm.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Beitrittserklärung';
  private history: string[] = [];
  items: MegaMenuItem[] = [];
  isLoggedIn!: boolean;
  isLoggedOut = true;
  canGoBack!: boolean;
  get user() {
    return this.token?.user;
  }
  get userPermissions() {
    return this.token?.permissions;
  }
  token: JWT_Payload | undefined;
  
  constructor(
    private config: PrimeNGConfig,
    private translateService: TranslateService,
    private auth: AuthService,
    private router: Router,
    private location: Location,
    private perm: PermService,
    private application: ApplicationService) {
    translateService.setDefaultLang('de');
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((x) => {
      this.history.push((<NavigationEnd>x).urlAfterRedirects);
      this.updateTopBar();
    });
  }
  ngOnInit(): void {
    this.translate('de');
    this.updateTopBar();
  }

  translate(lang: string) {
    console.log('lang', lang);
    this.translateService.use(lang);
    this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
  }

  private updateTopBar() {
    const authenticated = this.auth.isAuthenticated();
    const loggedIn = this.auth.isLoggedIn();
    this.token = this.auth.getToken();
    this.isLoggedIn = authenticated && loggedIn;
    this.isLoggedOut = !this.isLoggedIn;
    this.canGoBack = this.history.length > 1;

    this.updateMegaMenu();
  }

  private updateMegaMenu() {
    this.items = [
      {
        label: 'Admin-Bereich', icon: 'pi pi-fw pi-home',
        routerLink: 'admin',
        styleClass: 'admin',
        visible: (this.isLoggedIn && this.perm.hasPerm((p) => p.Administration.read)) ?? false,
      },
      {
        label: 'Meine Übersicht',
        routerLink: ['overview'],
        visible: (this.isLoggedIn) ?? false,
      },
      {
        label: 'Neuer Antrag',
        routerLink: '/form',
      },
      {
        label: 'Anmelden',
        routerLink: 'login',
        visible: (!this.isLoggedIn) ?? false,
      },
      {
        label: 'Abmelden',
        routerLink: 'logout',
        visible: (this.isLoggedIn) ?? false,
      },
    ];
  }

  back(_ev: Event): void {
    this.history.pop();
    this.canGoBack = this.history.length > 1;

    if (this.history.length > 0) {
      this.history.pop();
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
